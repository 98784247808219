/* form components that also includes bootstrap override classes */

/* BOOTSTRAP CLASSES - START */
a {
    color: $color-primary;
    font-weight: 700;
}

b,
strong {
    font-weight: 700;
}

small {
    color: #777;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
    font-family: "Bebas Neue", sans-serif;
    line-height: 1;
}

h1, .h1 {
    font-size: 56px;
    margin-top: 0;
}

h2,
.h2 {
    margin-bottom: 35px;
    margin-top: 25px;
}

h3,
.h3 {
    color: $color-primary;
    margin-bottom: 20px;
    margin-top: 0;
    line-height: 1.5;
    font-size: 32px;
}

h4,
.h4 {
    font-size: 24px;
    margin-top: 0;
}

h5,
.h5,
h6,
.h6 {
    line-height: 1.4;
}

.text-primary {
    color: $color-primary;
}

.bg-primary {
    background-color: $color-primary;
}

.bg-primary * {
    color: #fff !important;
}

.bg-primary a {
    text-decoration: underline;
}

textarea {
    resize: vertical;
}

.input-lg {
    padding: 0 16px;
}

.btn {
    height: 34px;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    font-weight: 400;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 4px;
    font-family: "Bebas Neue", sans-serif;
    letter-spacing: .4px;
    font-size: 16px;
    display: inline-flex;
    justify-content: center;
    overflow: hidden;
    position: relative;

    &::after {
        content: '';
        background: #fff;
        height: 155px;
        left: -75px;
        opacity: .2;
        position: absolute;
        top: -50px;
        -webkit-transform: rotate(35deg);
        transform: rotate(35deg);
        transition: all .55s cubic-bezier(.19,1,.22,1);
        width: 50px;
        z-index: 10;
    }

    &:active::after,
    &:focus::after,
    &:hover::after {
        left: 80%;
        transition: all .55s cubic-bezier(.19,1,.22,1);
    }
}





.btn-group-lg > .btn,
.btn-lg {
    height: 46px;
    padding: 9px 16px;
    font-size: 22px;
}

.btn-group-sm > .btn,
.btn-sm {
    height: 30px;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 15px;
    padding-top: 3px;
    padding-bottom: 3px;
}

.btn-group-xs > .btn,
.btn-xs {
    height: 22px;
    padding-left: 8px;
    padding-right: 8px;
}

.btn-default {
    color: #fff;
    background-color: $color-default;
    border-color: $color-default;
}

.btn-default:active,
.btn-default:hover,
.btn-default:focus,
.btn-default.active.focus, .btn-default.active:focus, .btn-default.active:hover, .btn-default:active.focus, .btn-default:active:focus, .btn-default:active:hover, .open > .dropdown-toggle.btn-default.focus, .open > .dropdown-toggle.btn-default:focus, .open > .dropdown-toggle.btn-default:hover {
    color: #fff;
    background-color: $color-default-hover;
    border-color: $color-default-hover;
}


.btn-default-outline {
    color: $color-default;
    background-color: #fff;
    border-color: $color-default;
}

.btn-default-outline:hover,
.btn-default-outline:focus,
.btn-default-outline.active.focus,
.btn-default-outline.active:focus,
.btn-default-outline.active:hover,
.btn-default-outline:active.focus,
.btn-default-outline:active:focus,
.btn-default-outline:active:hover,
.open > .dropdown-toggle.btn-default-outline.focus,
.open > .dropdown-toggle.btn-default-outline:focus,
.open > .dropdown-toggle.btn-default-outline:hover {
    color: #fff;
    background-color: $color-default;
    border-color: $color-default;
}


.btn-primary {
    color: #fff;
    background-color: $color-primary;
    border-color: $color-primary;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary:active.focus,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover {
    color: #fff;
    background-color: $color-primary-hover;
    border-color: $color-primary-hover;
}

.btn-primary-outline {
    color: $color-primary;
    background-color: #fff;
    border-color: $color-primary;
}

.btn-primary-outline:hover,
.btn-primary-outline:focus,
.btn-primary-outline.active.focus,
.btn-primary-outline.active:focus,
.btn-primary-outline.active:hover,
.btn-primary-outline:active.focus,
.btn-primary-outline:active:focus,
.btn-primary-outline:active:hover,
.open > .dropdown-toggle.btn-primary-outline.focus,
.open > .dropdown-toggle.btn-primary-outline:focus,
.open > .dropdown-toggle.btn-primary-outline:hover {
    color: #fff;
    background-color: $color-primary;
    border-color: $color-primary;
}

.btn-white-outline {
    color: #fff;
    background-color: transparent;
    border-color: #fff;
}

.btn-white-outline:hover,
.btn-white-outline:focus,
.btn-white-outline.active.focus,
.btn-white-outline.active:focus,
.btn-white-outline.active:hover,
.btn-white-outline:active.focus,
.btn-white-outline:active:focus,
.btn-white-outline:active:hover,
.open > .dropdown-toggle.btn-primary-outline.focus,
.open > .dropdown-toggle.btn-primary-outline:focus,
.open > .dropdown-toggle.btn-primary-outline:hover {
    color: #fff;
    background-color: $color-primary;
    border-color: #fff;
}

.btn-success {
    color: $color-success;
    background-color: #fff;
    border-color: $color-success;
}

.btn-success:hover,
.btn-success:focus,
.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success:active.focus,
.btn-success:active:focus,
.btn-success:active:hover,
.open > .dropdown-toggle.btn-success.focus,
.open > .dropdown-toggle.btn-success:focus,
.open > .dropdown-toggle.btn-success:hover {
    background-color: $color-success;
    border-color: $color-success;
}

.btn-info {
    color: $color-info;
    background-color: #fff;
    border-color: $color-info;
}

.btn-info:hover,
.btn-info:focus,
.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info:active.focus,
.btn-info:active:focus,
.btn-info:active:hover,
.open > .dropdown-toggle.btn-info.focus,
.open > .dropdown-toggle.btn-info:focus,
.open > .dropdown-toggle.btn-info:hover {
    background-color: $color-info;
    border-color: $color-info;
}

.btn-warning {
    color: $color-warning;
    background-color: #fff;
    border-color: $color-warning;
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning:active.focus,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open > .dropdown-toggle.btn-warning.focus,
.open > .dropdown-toggle.btn-warning:focus,
.open > .dropdown-toggle.btn-warning:hover {
    background-color: $color-warning;
    border-color: $color-warning;
}

.btn-danger {
    color: $color-danger;
    background-color: #fff;
    border-color: $color-danger;
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger:active.focus,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open > .dropdown-toggle.btn-danger.focus,
.open > .dropdown-toggle.btn-danger:focus,
.open > .dropdown-toggle.btn-danger:hover {
    background-color: $color-danger;
}

.btn-link {
    color: $color-primary;
}

.btn-link:hover,
.btn-link:focus {
    color: $color-primary-hover;
    text-decoration: none;
    background-color: rgba(165, 13, 117, .05);
    box-shadow: none;
}

.btn-link .la {
    text-decoration: none;
    margin-right: 3px;
}

.navbar-toggle {
    padding: 16px 0;
}


.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group > .btn,
.input-group-btn:last-child > .dropdown-toggle,
.input-group-btn:first-child > .btn:not(:first-child),
.input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
    padding: 6px 12px;
    box-shadow: none;
    border: 1px solid #ccc;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
}

.form-group {
    margin-bottom: 24px;
}

.form-control {
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: none;
    -webkit-transition: border-color ease-in-out .1s;
    transition: border-color ease-in-out .1s;
}

.form-control:focus {
    border: 1px solid $color-primary;
    background: #fff;
    box-shadow: none;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
    background-color: #eee;
    opacity: 1;
    border: 1px solid rgba(45, 50, 56, 0.1);
}

.control-label {
    font-weight: 400;
    color: $color-primary-hover;
    font-size: 15px;
}

.form-control-static {
    padding-top: 0;
}

.form-inline .form-control-static {
    vertical-align: middle;
}

.form-group label > .btn {
    padding: 0;
    color: inherit;
    height: 20px;
    width: auto;
    vertical-align: baseline;
}

hr {
    border-top: 1px solid #CCCCCC;
    margin-bottom: 30px;
}

.checkbox-inline + .checkbox-inline,
.radio-inline + .radio-inline {
    margin-left: 24px;
}

.well {
    border-radius: 0;
    padding: 15px;
    box-shadow: none;
}

.label {
    font-weight: 400;
    border-radius: .1em;
}

.label-primary {
    background-color: $color-primary;
    color: #fff;
    border: 1px solid $color-primary;
}

.label-primary-outline {
    background-color: #fff;
    color: $color-primary;
    border: 1px solid $color-primary;
}

.label-warning {
    background-color: $color-warning;
}

.label-success {
    background-color: $color-success;
}

.label-info, .label.premium {
    background-color: $color-info;
}

.label-danger {
    background-color: $color-danger;
}

.panel-title a {
    font-size: 15px;
    display: block;
}

.panel-title a:hover,
.panel-title a:focus {
    text-decoration: none;
}

.panel,
.panel-group .panel,
.panel-group .panel-heading {
    border-radius: 0;
}

.panel {
    box-shadow: none;
}

.panel-default {
    border-color: #fff;
}

.panel-heading {
    padding: 8px 15px;
    border-radius: 0;
}

.panel-icons .panel-heading {
    padding: 5px 15px;
}

.panel-footer {
    padding: 10px 24px;
    background-color: #fff;
}

.panel-footer .btn.btn-block {
    box-shadow: none;
}

.panel-footer .btn.btn-block:hover,
.panel-footer .btn.btn-block:focus {
    background-color: #FAECF6;
    border-radius: 0;
    text-decoration: none;
}

.table > thead > tr > th {
    font-weight: 700;
    color: $color-primary;
    text-transform: uppercase;
    font-size: 13px;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
    vertical-align: middle;
}

.table-hover > tbody > tr:hover {
    background-color: rgba(237, 34, 172, 0.05);
}

table a {
    font-weight: 400;
    color: $color-primary-hover;
}

table .btn {
    padding: 0 5px;
    margin-right: 10px;
    height: auto;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
}

table .btn-link:hover,
table .btn-link:focus {
    text-decoration: none;
    background-color: rgba(165, 13, 117, .05);
}

table .checkbox {
    margin: 0;
}


.pagination > .disabled > a,
.pagination > .disabled > a:focus,
.pagination > .disabled > a:hover,
.pagination > .disabled > span,
.pagination > .disabled > span:focus,
.pagination > .disabled > span:hover {
    border-color: transparent;
    background-color: transparent;
}


.page-navigation {
    margin-top: 16px;
}

.pagination {
    display: inline-block;
    padding-left: 15px;
    margin: 0;
    border-radius: 0;
}

.pagination > li > a, .pagination > li > span {
    border: 1px solid transparent;
    background-color: transparent;
    color: $color-default;
}

.pagination > li > a:hover, .pagination > li > span:hover, .pagination > li > a:focus, .pagination > li > span:focus {
    border-color: transparent;
    background-color: $color-default;
    color: #fff;
}

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
    border-color: transparent;
    background-color: $color-default;
    color: #fff;
}

.pagination > li:last-child > a, .pagination > li:last-child > span, .pagination > li:first-child > a, .pagination > li:first-child > span {
    font-size: 12px;
    padding: 9px;
}



.alert {
    border-radius: 0;
    box-shadow: none;
    padding: 8px 24px;
    color: #fff;
}

.alert-info {
    background-color: $color-info;
    border-color: $color-info;
}

.alert-danger {
    background-color: $color-danger;
    border-color: $color-danger;
}

.alert-warning {
    background-color: $color-warning;
    border-color: $color-warning;
}

.alert-success {
    background-color: $color-success;
    border-color: $color-success;
}

.alert-dismissable .close,
.alert-dismissible .close {
    top: -3px;
    right: -16px;
}

.alert a {
    font-weight: 700;
    text-decoration: underline;
    color: #fff;
}

.navbar-nav > li > a {
    color: #fff;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    font-family: "Bebas Neue",sans-serif;
    font-size: 22px;
}



.nav .open > a, .nav .open > a:focus, .nav .open > a:hover {
    background-color: initial;
    border-color: initial;
}

.tooltip {
    font-family: inherit;
    font-size: 14px;
}

.modal-content {
    border-radius: 0;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.19);
}

.dropdown-menu {
    border-radius: 0;
    box-shadow: none;
    background-color: $color-primary;
    color: #fff;
    padding: 0;
    border: 4px solid $color-default;
    border-radius: 4px;
}

.dropdown-menu > li > a {
    white-space: nowrap;
    padding: 7px 15px;
    color: #fff;
    font-size: 20px;
    font-family: "Bebas Neue",sans-serif;
}

.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover {
    color: #fff;
    background-color: $color-default-hover;
}

.nav-pills > li {
    margin-right: 8px;
}

.nav-pills > li > a {
    border-radius: 23px;
    border: 1px solid transparent;
    -webkit-transition: all 0.1s;
    transition: all 0.1s;
}

.nav-pills > li.active > a,
.nav-pills > li.active > a:focus,
.nav-pills > li.active > a:hover {
    background-color: $color-primary;
    border: 1px solid $color-primary;
}

.nav-pills > li > a:hover,
.nav-pills > li > a:focus {
    background-color: rgba(237, 34, 172, 0.05);
    color: inherit;
    border: 1px solid #ddd;
}



button.close {
    cursor: pointer !important;
    text-shadow: none;
    opacity: .5;
}

.badge {
    vertical-align: text-top;
}


/* BOOTSTRAP CLASSES - END */

select.form-control {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20fill%3D%22%23555555%22%20%0A%09%20width%3D%2224px%22%20height%3D%2224px%22%20viewBox%3D%22-261%20145.2%2024%2024%22%20style%3D%22enable-background%3Anew%20-261%20145.2%2024%2024%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Cpath%20d%3D%22M-245.3%2C156.1l-3.6-6.5l-3.7%2C6.5%20M-252.7%2C159l3.7%2C6.5l3.6-6.5%22%2F%3E%0A%3C%2Fsvg%3E") !important;
    background-size: 20px !important;
    padding-right: 25px;
    background-repeat: no-repeat !important;
    background-position: right 2% center !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-position-x: 100%;
    /*safari placement*/
    background-position-y: 40%;
    /*safari placement*/
}

select.form-control.input-sm {
    line-height: 1;
}

/* hide the default arrow in IE */
select.form-control::-ms-expand {
    display: none;
}



/* SWITCH SLIDER - START */

.switch {
    position: relative;
    display: inline-block;
    width: 56px;
    height: 30px;
    vertical-align: bottom;
}

.switch input {
    display: none;
}

.switch-slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .2s;
    transition: .2s;
    border-radius: 34px;

    &:hover {
        opacity: .7;
    }

    &::before {
        position: absolute;
        content: "";
        height: 24px;
        width: 24px;
        left: 3px;
        bottom: 3px;
        background-color: #fff;
        -webkit-transition: .2s;
        transition: .2s;
        border-radius: 50%;
    }
}




input:checked + .switch-slider,
input:checked + input + .switch-slider {
    background-color: $color-primary;
}

input:focus + .switch-slider,
input:focus + input + .switch-slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .switch-slider:before,
input:checked + input + .switch-slider:before {
    -webkit-transform: translateX(26px);
    transform: translateX(26px);
}

/* SWITCH SLIDER - END */

/* SELECT2 MULTISELECT - START */

.select2 {
    width: 100% !important;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: $color-body;
    line-height: 34px;
    font-size: 14px;
}

.select2-container--default .select2-selection--single {
    @extend .form-control;
    padding: 0;
}

.select2-results__options {
    font-size: 14px;
}

.select2-results__option[aria-selected] {
    cursor: default;
}

.select2-container .select2-selection--single {
    min-height: 34px;
    cursor: default;
}

.select2-container--default .select2-selection--multiple {
    @extend .form-control;
    padding: 0;
    min-height: 34px;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20fill%3D%22%23555555%22%20%0A%09%20width%3D%2224px%22%20height%3D%2224px%22%20viewBox%3D%22-261%20145.2%2024%2024%22%20style%3D%22enable-background%3Anew%20-261%20145.2%2024%2024%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Cpath%20d%3D%22M-245.3%2C156.1l-3.6-6.5l-3.7%2C6.5%20M-252.7%2C159l3.7%2C6.5l3.6-6.5%22%2F%3E%0A%3C%2Fsvg%3E");
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: right 4px center;
    height: auto;
}

.select2-container--default.select2-container--focus .select2-selection--multiple,
.select2-container--default.select2-container--open.select2-container--above .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple,
.select2-container--default.select2-container--open.select2-container--below .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
    @extend .form-control:focus;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    color: #fff;
    background-color: $color-primary;
    border-color: $color-primary;
    font-size: 14px;
    border-radius: 0;
    padding: 0 8px;
    margin-top: 4px;
    height: 22px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    color: #fff;
    font-size: 24px;
    font-weight: normal;
    margin-right: 8px;
    vertical-align: text-top;
    line-height: .7;
    cursor: default;
    background: transparent;
    border: none;
    padding: 0;
}

.select2-container--default .select2-results__option[aria-selected=true] {
    background-color: #fff;
    color: #bbb;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: $color-primary;
}

.select2-container--default .select2-search--inline .select2-search__field {
    font-size: 14px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 3px;
    right: 0;
    background-size: 20px !important;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20fill%3D%22%23555555%22%20%0A%09%20width%3D%2224px%22%20height%3D%2224px%22%20viewBox%3D%22-261%20145.2%2024%2024%22%20style%3D%22enable-background%3Anew%20-261%20145.2%2024%2024%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Cpath%20d%3D%22M-245.3%2C156.1l-3.6-6.5l-3.7%2C6.5%20M-252.7%2C159l3.7%2C6.5l3.6-6.5%22%2F%3E%0A%3C%2Fsvg%3E");
    background-position: right 6px center;
    background-repeat: no-repeat;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    display: none;
}

.select2-dropdown {
    border-radius: 0;
}

/* SELECT2 MULTISELECT - END */

/* CUSTOM CHECKBOX AND RADIO - START */

.checkbox {
    padding-left: 20px;
}

.checkbox label {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 5px;

    &::before {
        content: "";
        display: inline-block;
        position: absolute;
        width: 18px;
        height: 18px;
        left: 0;
        margin-left: -20px;
        border: 1px solid $color-default;
        border-radius: 0;
        opacity: 1;
        background-color: #fff;
        -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
        transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    }

    &::after {
        display: inline-block;
        position: absolute;
        width: 18px;
        height: 18px;
        border-radius: 2px;
        left: 0;
        top: 0;
        margin-left: -20px;
        padding-left: 2px;
        padding-top: 0px;
        font-size: 14px;
        color: #fff;
        background-color: $color-primary;
        line-height: 1.3;
        font-weight: 600;
    }
}

.radio label,
.checkbox label,
.radio-inline,
.checkbox-inline {
    cursor: default;
}

.radio label:hover,
.radio label:focus,
.checkbox label:hover,
.checkbox label:focus {
    background: rgba(237, 34, 172, 0.05);
}

.checkbox input[type="checkbox"],
.checkbox input[type="radio"] {
    opacity: 0;
    z-index: 1;
    cursor: pointer;
}

.checkbox input[type="checkbox"]:focus + input + label:before,
.checkbox input[type="radio"]:focus + input + label:before,
.checkbox input[type="checkbox"]:focus + label:before,
.checkbox input[type="radio"]:focus + label:before {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

.checkbox input[type="checkbox"]:checked + input + label:after,
.checkbox input[type="radio"]:checked + input + label:after,
.checkbox input[type="checkbox"]:checked + label:after,
.checkbox input[type="radio"]:checked + label:after {
    font-family: "LineAwesome";
    content: "\f17b";
}

.checkbox input[type="checkbox"]:indeterminate + input + label:after,
.checkbox input[type="radio"]:indeterminate + input + label:after,
.checkbox input[type="checkbox"]:indeterminate + label:after,
.checkbox input[type="radio"]:indeterminate + label:after {
    display: block;
    content: "";
    width: 10px;
    height: 3px;
    background-color: #555555;
    border-radius: 0;
    margin-left: -16.5px;
    margin-top: 7px;
}

.checkbox input[type="checkbox"]:disabled,
.checkbox input[type="radio"]:disabled {
    cursor: not-allowed;
}

.checkbox input[type="checkbox"]:disabled + label,
.checkbox input[type="radio"]:disabled + label {
    opacity: 0.65;
    cursor: not-allowed;
}

.checkbox input[type="checkbox"]:disabled + label:before,
.checkbox input[type="radio"]:disabled + label:before {
    background-color: #eeeeee;
    cursor: not-allowed;
}

.checkbox.checkbox-circle label:before {
    border-radius: 50%;
}

.checkbox.checkbox-inline {
    margin-top: 0;
}

.radio {
    padding-left: 20px;
}

.radio label {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 5px;

    &::before {
        content: "";
        display: inline-block;
        position: absolute;
        width: 17px;
        height: 17px;
        left: 0;
        margin-left: -20px;
        border: 1px solid $color-default;
        border-radius: 50%;
        background-color: #fff;
        -webkit-transition: border 0.15s ease-in-out;
        transition: border 0.15s ease-in-out;
    }

    &::after {
        display: inline-block;
        position: absolute;
        content: " ";
        width: 7px;
        height: 7px;
        left: 5px;
        top: 5px;
        margin-left: -20px;
        border-radius: 50%;
        background-color: $color-primary;
        -webkit-transform: scale(0, 0);
        transform: scale(0, 0);
        -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
        transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
        transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
        transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33), -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    }
}



.radio input[type="radio"] {
    opacity: 0;
    z-index: 1;
    cursor: pointer;
}

.radio input[type="radio"]:focus + input + label:before,
.radio input[type="radio"]:focus + label:before {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

.radio input[type="radio"]:checked + input + label:after,
.radio input[type="radio"]:checked + label:after {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
}

.radio input[type="radio"]:disabled {
    cursor: not-allowed;
}

.radio input[type="radio"]:disabled + label {
    opacity: 0.65;
}

.radio input[type="radio"]:disabled + label:before {
    cursor: not-allowed;
}

.radio.radio-inline {
    margin-top: 0;
}

input[type="checkbox"].styled:checked + label:after,
input[type="radio"].styled:checked + label:after {
    font-family: 'LineAwesome';
    content: "\f17b";
}

input[type="checkbox"] .styled:checked + label:before,
input[type="radio"] .styled:checked + label:before {
    color: #fff;
}

input[type="checkbox"] .styled:checked + label:after,
input[type="radio"] .styled:checked + label:after {
    color: #fff;
}

/* CUSTOM CHECKBOX AND RADIO - END */
