/* global boilerplate classes to start a new project */

body {
    color: $color-body;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 400;
    background-color: $color-background;
    font-size: 15px;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) */
@font-face {
    font-family: 'Bebas Neue';
    src: url('../../assets/fonts/bebasneue_bold-webfont.eot');
    src: url('../../assets/fonts/bebasneue_bold-webfont.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/bebasneue_bold-webfont.woff2') format('woff2'), url('../../assets/fonts/bebasneue_bold-webfont.woff') format('woff'), url('../../assets/fonts/bebasneue_bold-webfont.ttf') format('truetype'), url('../../assets/fonts/bebasneue_bold-webfont.svg#bebas_neuebold') format('svg');
    font-weight: bold;
    font-style: normal;
}


/*IE incompatibility message*/

.ie-browser {
    display: none;
    background-color: #111;
    color: #fff;
    text-align: center;
    font-size: 18px;
    margin-top: 24px;
    padding: 16px;
    position: relative;
    z-index: 100;
}

html[data-useragent*='MSIE 10.0'] .ie-browser {
    display: block;
}

/*end of IE incompatibility message*/

.loading.la {
    font-size: 64px;
    color: $color-primary;
}

.js-find-location {
    background-color: $color-primary;
    color: #fff;
}

.js-find-location:hover,
.js-find-location:focus,
.js-find-location:active {
    background-color: $color-primary-hover;
    color: #fff;
}

.muted {
    opacity: .6;
}

.highlighter,
.make-target:target {
    animation: highlighter 4s .3s forwards;
    -webkit-animation: highlighter 4s .3s forwards;
    -o-animation: highlighter 4s .3s forwards;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

@-webkit-keyframes highlighter {
    0% {
        box-shadow: 0 0 0 8px #fff, 0 0 0 16px $color-primary;
    }
}

@keyframes highlighter {
    0% {
        box-shadow: 0 0 0 8px #fff, 0 0 0 16px $color-primary;
    }
}

.la {
    font-size: 1.4em;
    vertical-align: text-bottom;
}

.la-spin {
    -webkit-animation: fa-spin 2s infinite cubic-bezier(0.18, 0.89, 0.32, 1.28);
    animation: fa-spin 2s infinite cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.text-white {
    color: #fff !important;
}

.text-default {
    color: $color-default !important;
}

.bg-white {
    background-color: #fff !important;
}

.bg-black {
    background-color: #000 !important;
}

.bg-transparent {
    background-color: transparent !important;
}

.bg-grey,
.bg-gray {
    background-color: $color-background !important;
}

.btn-dark,
.label-dark {
    color: #fff;
    background-color: $color-body;
    border: 1px solid $color-body;
}

.btn-dark:hover,
.btn-dark:focus {
    color: #fff;
    background-color: #22262a;
}

.btn-shortlisted {
    color: #D43F3A;
    border-color: #D43F3A;
    padding: 5px;
}

.js-shortlist-button:hover,
.js-shortlist-button:focus {
    border-color: #D43F3A;
    padding: 5px;
    background-color: #D43F3A;
}

.btn-clear {
    @extend .form-control;
    border-left: none !important;
    cursor: default;
}

.btn-clear:hover,
.btn-clear:focus {
    background-color: #ccc;
}


.result-list,
.job-list-container,
.candidate-list-container {
    .js-shortlist-button {
        height: 30px;
        padding: 5px 10px;
        font-size: 12px;
        line-height: 1.5;
        background-color: #fff;
    }
}



.btn.btn-icon {
    padding: 6px;
    border-radius: 50%;
    height: 34px;
    width: 34px;
    text-decoration: none;
}

.btn-lg.btn-icon {
    height: 46px;
    width: 46px;
}

.btn-sm.btn-icon {
    height: 30px;
    width: 30px;
}

.btn-xs.btn-icon {
    height: 22px;
    width: 22px;
    padding: 2px;
}

/* QUICK SEARCH - START */

.quick-search .input-group:before {
    content: "\f2eb";
    font-family: Lineawesome;
    color: #777;
    position: absolute;
    margin-left: 10px;
    margin-top: 5px;
    font-size: 18px;
    z-index: 4;
}

.quick-search-input {
    padding-left: 35px;
    border-right: none;
    border-radius: 4px;
}
/* QUICK SEARCH - END */

.panel-control {
    height: 100%;
    position: relative;
    float: right;
    padding-top: 8px;

    .nav-pills > li > a {
        padding: 2px 8px;
        font-size: 12px;
    }
}

/* Fix the double click issue with the bootstrap tabs when using google charts. */
/* START */
.tab-content {
    clear: both;
}

.tab-chart .tab-content > {
    .tab-pane {
        display: block;
        height: 0;
        overflow-y: hidden;
    }

    .active {
        height: auto;
    }
}

/* END */

.scrolltotop {
    text-align: center;
    position: fixed;
    z-index: 10;
    bottom: 75px;
    right: 40px;
    width: 40px !important;
    height: 40px !important;
    padding: 9px !important;
    display: none;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.19);
}

/* SLICK SLIDER - START */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.slick-list:focus {
    outline: 0;
}

.slick-list.dragging {
    cursor: pointer;
}

.slick-slider .slick-list,
.slick-slider .slick-track {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
}

.slick-track:after,
.slick-track:before {
    display: table;
    content: "";
}

.slick-track:after {
    clear: both;
}

.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
    background-color: transparent;
    margin: 10px;
    padding: 0 24px;
}

[dir=rtl] .slick-slide {
    float: right;
}

.slick-slide img {
    display: block;
    margin: auto;
    border-radius: 24px;
}

.slick-slide.slick-loading img {
    display: none;
}

.slick-slide.dragging img {
    pointer-events: none;
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-loading .slick-slide {
    visibility: hidden;
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
    display: none;
}

.slick-loading .slick-list:before {
    font-family: LineAwesome;
    content: "\f1c3";
    -webkit-animation: fa-spin 2s infinite linear;
    animation: fa-spin 2s infinite linear;
    color: $color-primary;
    font-size: 3em;
    position: absolute;
    left: 48%;
    top: 40%;
    -webkit-transform: translate(-48%, -10%);
    transform: translate(-48%, -10%);
}

.slick-next,
.slick-prev {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: default;
    color: $color-default;
    border: 1px solid $color-default;
    outline: 0;
    background: 0 0;
    padding: 6px;
    border-radius: 0;
    height: 34px;
    width: 34px;
    z-index: 1;
    background-color: transparent;
}

.slick-next:focus,
.slick-next:hover,
.slick-prev:focus,
.slick-prev:hover {
    outline: 0;
    background: 0 0;
    background-color: #fff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16);
}


.slick-next.slick-disabled:before,
.slick-prev.slick-disabled:before {
    opacity: .25;
}

.slick-next:before,
.slick-prev:before {
    font-family: LineAwesome;
    font-size: 20px;
    line-height: 1;
    opacity: 1;
    color: $color-default;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
    left: 0;
}

[dir=rtl] .slick-prev {
    right: -25px;
    left: auto;
}

.slick-prev:before {
    content: "\f120";
}

[dir=rtl] .slick-prev:before {
    content: "\f121";
}

.slick-next {
    right: 0;
}

[dir=rtl] .slick-next {
    right: auto;
    left: -25px;
}

.slick-next:before {
    content: "\f121";
}

[dir=rtl] .slick-next:before {
    content: "\f120";
}

.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}

.slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
}

.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: 0;
    background: 0 0;
}

.slick-dots li button:focus,
.slick-dots li button:hover {
    outline: 0;
}

.slick-dots li button:focus:before,
.slick-dots li button:hover:before {
    opacity: 1;
}

.slick-dots li button:before {
    font-family: LineAwesome;
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: "•";
    text-align: center;
    opacity: .25;
    color: $color-primary;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
    opacity: .75;
    color: #000;
}
/* SLICK SLIDER - END */


/* JQUERY-UI ATUTOCOMPLETE DROPDOWN STYLING - START */

.ui-menu {
    z-index: 9999 !important;

    .ui-menu-item a { /*normal state of the list*/
        color: #555;
    }
}

/*styling for the dropdown*/

.ui-widget {
    border-radius: 0 !important;
    font-weight: 400;
    font-size: 16px !important;
    border-color: #ABADAF !important;
}


/*hover state of the list*/

.ui-state-focus {
    background-color: $color-primary !important;
    background-image: none !important;
    color: #fff !important;
    border-radius: 0 !important;
    border-color: transparent !important;
    cursor: default !important;
}

/* JQUERY-UI ATUTOCOMPLETE DROPDOWN STYLING - END */


/* VALIDATION STYLINGS - START */

.field-validation-error {
    margin-top: 8px;
    display: inline-block;
    font-size: 14px;
    background: rgba(241, 178, 91, .15);
    border-radius: 2px;
    padding: 0 4px;
    color: #a94442;
}

.input-validation-error {
    box-shadow: inset 0 0 0 1px #a94442;
    border-color: #a94442;
}

/* VALIDATION STYLINGS - END */

.recruiter-map {
    width: 100%;
    height: 100%;
}

.control-label-filter {
    font-weight: normal;
    line-height: 1.4;
    font-size: 14px;
    color: $color-default-hover;
    margin-bottom: 5px;
}


/* FMCV OPTIONS - START */

.employers-chk-list .checkbox label > small:first-of-type {
    margin-left: 10px;
}

.attention-grabber {
    box-shadow: 0 0 100em 0 rgba(0,0,0,.65);
    box-shadow: 0 0 0 100vmax rgba(0,0,0,.65);
    position: relative;
    z-index: 999;
}


.opt-features {
    margin-bottom: 32px;
    list-style-type: none;

    li {
        position: relative;
        margin: 8px 0;

        &::before {
            content: '\f17b';
            font-family: "LineAwesome";
            margin-right: 4px;
            font-size: 20px;
            display: inline-block;
            position: absolute;
            left: -28px;
            top: -4px;
        }

        &.striked {
            text-decoration: line-through;
            opacity: .6;

            &::before {
                content: '\f342';
            }
        }
    }
}



.fmcv-option-panel {
    position: absolute;
    background-color: #fff;
    padding: 32px;
}



.divider-or {
    border-bottom: 1px dashed rgba(0,0,0,.4);
    position: relative;
    margin: 32px 16px;

    &::before {
        content: 'OR';
        border-radius: 50%;
        border: 1px solid rgba(0,0,0,.2);
        padding: 9px;
        background-color: #FFF;
        position: absolute;
        left: 50%;
        top: -20px;
        width: 40px;
        height: 40px;
        transform: translateX(-50%);
    }
}


/* FMCV OPTIONS - END */


.outer {
    position: relative;

    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: (3 / 4) * 100%;
    }

    > .inner {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
    }
}

.loading {
    width: 45px;
    height: 45px;
    border-color: #00334b #00334b #00334b #eee;
    border-style: solid;
    border-width: 6px;
    border-radius: 50%;
    -webkit-animation: rotate 1s infinite linear;
    animation: rotate 1s infinite linear;
}

@-webkit-keyframes rotate {
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn);
    }
}

@keyframes rotate {
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn);
    }
}


/** LOADING ANIMATION **/
/*Animation by https://codepen.io/pawelqcm/*/
.loading-backdrop {
    cursor: not-allowed;
    background: rgba(255,255,255,.95);
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1040;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: stretch;
}

#cooking {
    position: relative;
    margin: 0 auto;
    top: 0;
    width: 75vh;
    height: 75vh;
    overflow: hidden;
    margin-top: -300px;
}

.loading-backdrop h3 {
    position: relative;
    margin: 0 auto;
    top: 25vh;
    width: 100%;
    text-align: center;
    color: $color-primary;
    opacity: .75;
    animation: pulse 2.5s linear infinite;
    font-size: 54px;
}

#cooking .bubble {
    position: absolute;
    border-radius: 100%;
    box-shadow: 0 0 0.25vh #4d4d4d;
    opacity: 0;
}

#cooking .bubble:nth-child(1) {
    margin-top: 2.5vh;
    left: 58%;
    width: 2.5vh;
    height: 2.5vh;
    background-color: #454545;
    animation: bubble 2s cubic-bezier(0.53, 0.16, 0.39, 0.96) infinite;
}

#cooking .bubble:nth-child(2) {
    margin-top: 3vh;
    left: 52%;
    width: 2vh;
    height: 2vh;
    background-color: #3d3d3d;
    animation: bubble 2s ease-in-out .35s infinite;
}

#cooking .bubble:nth-child(3) {
    margin-top: 1.8vh;
    left: 50%;
    width: 1.5vh;
    height: 1.5vh;
    background-color: #EDD583;
    animation: bubble 1.5s cubic-bezier(0.53, 0.16, 0.39, 0.96) 0.55s infinite;
}

#cooking .bubble:nth-child(4) {
    margin-top: 2.7vh;
    left: 56%;
    width: 1.2vh;
    height: 1.2vh;
    background-color: #2b2b2b;
    animation: bubble 1.8s cubic-bezier(0.53, 0.16, 0.39, 0.96) 0.9s infinite;
}

#cooking .bubble:nth-child(5) {
    margin-top: 2.7vh;
    left: 63%;
    width: 1.1vh;
    height: 1.1vh;
    background-color: #EDD583;
    animation: bubble 1.6s ease-in-out 1s infinite;
}

#cooking #area {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 50%;
    height: 50%;
    background-color: transparent;
    transform-origin: 15% 60%;
    animation: flip 2.1s ease-in-out infinite;
}

#cooking #area #sides {
    position: absolute;
    width: 100%;
    height: 100%;
    transform-origin: 15% 60%;
    animation: switchSide 2.1s ease-in-out infinite;
}

#cooking #area #sides #handle {
    position: absolute;
    bottom: 18%;
    right: 80%;
    width: 35%;
    height: 20%;
    background-color: transparent;
    border-top: 1vh solid #333;
    border-left: 1vh solid transparent;
    border-radius: 100%;
    transform: rotate(20deg) rotateX(0deg) scale(1.3, 0.9);
}

#cooking #area #sides #pan {
    position: absolute;
    bottom: 20%;
    right: 30%;
    width: 50%;
    height: 8%;
    background-color: #333;
    border-radius: 0 0 1.4em 1.4em;
    transform-origin: -15% 0;
}

#cooking #area #pancake {
    position: absolute;
    top: 24%;
    width: 100%;
    height: 100%;
    transform: rotateX(85deg);
    animation: jump 2.1s ease-in-out infinite;
}

#cooking #area #pancake #pastry {
    position: absolute;
    bottom: 26%;
    right: 37%;
    width: 40%;
    height: 45%;
    background-color: #EDD583;
    box-shadow: 0 0 3px 0 #EDD583;
    border-radius: 100%;
    transform-origin: -20% 0;
    animation: fly 2.1s ease-in-out infinite;
}

@keyframes jump {
    0% {
        top: 24%;
        transform: rotateX(85deg);
    }

    25% {
        top: 10%;
        transform: rotateX(0deg);
    }

    50% {
        top: 30%;
        transform: rotateX(85deg);
    }

    75% {
        transform: rotateX(0deg);
    }

    100% {
        transform: rotateX(85deg);
    }
}

@keyframes flip {
    0% {
        transform: rotate(0deg);
    }

    5% {
        transform: rotate(-27deg);
    }

    30%, 50% {
        transform: rotate(0deg);
    }

    55% {
        transform: rotate(27deg);
    }

    83.3% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

@keyframes switchSide {
    0% {
        transform: rotateY(0deg);
    }

    50% {
        transform: rotateY(180deg);
    }

    100% {
        transform: rotateY(0deg);
    }
}

@keyframes fly {
    0% {
        bottom: 26%;
        transform: rotate(0deg);
    }

    10% {
        bottom: 40%;
    }

    50% {
        bottom: 26%;
        transform: rotate(-190deg);
    }

    80% {
        bottom: 40%;
    }

    100% {
        bottom: 26%;
        transform: rotate(0deg);
    }
}

@keyframes bubble {
    0% {
        transform: scale(0.15, 0.15);
        top: 80%;
        opacity: 0;
    }

    50% {
        transform: scale(1.1, 1.1);
        opacity: 1;
    }

    100% {
        transform: scale(0.33, 0.33);
        top: 60%;
        opacity: 0;
    }
}

@keyframes pulse {
    0% {
        transform: scale(1, 1);
        opacity: .25;
    }

    50% {
        transform: scale(1, 1);
        opacity: 1;
    }

    100% {
        transform: scale(1, 1);
        opacity: .25;
    }
}
